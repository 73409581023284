import React, { useContext, useEffect, useState } from 'react'

import { theme } from '@middesk/components'
import { useFormikContext } from 'formik'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

import { Page, PageProps } from '../components/Page'
import TaxRegistrationLineItems from '../components/TaxRegistrationLineItems'
import { COLORS } from '../components/System/Colors'
import Line from '../components/System/Line'
import Loader from '../components/System/Loader'
import Pencil from '../components/System/Icons/Pencil'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { AccountContext } from '../contexts/AccountProvider'
import { AuthContext } from '../contexts/AuthProvider'
import { StateDataContext } from '../contexts/StateDataProvider'
import api from '../lib/api'
import {
  PackageTypes,
  PackageVariations,
  STATE_MAP,
  REGISTRATION_REASONS,
  ACCOUNTING_BASIS_OPTIONS,
  PAYROLL_FREQUENCY_OPTIONS,
  MANAGED_BY_MIDDESK,
  BOTH_SUI_AND_SWH_TYPE,
  AGENT_INDIVIDUAL,
  ENTITY_TYPE_REVIEW_LABELS,
  TAXED_AS_ENTITY_TYPE_LABELS,
  LLC_STRUCTURES,
  GUSTO_ACCOUNT_SLUG
} from '../lib/constants'
import {
  formatAddress,
  blockedByPayrollDateMessage,
  blockedByHiredDateMessage,
  localTaxLineItems,
  fqLineItemsFromApplication
} from '../lib/helpers'
import {
  FormValues,
  PackageType,
  RegistrationType,
  Agency,
  Question
} from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { MAX_WIDTH_MOBILE } from '../components/Body'

const { spacing, colors, typography } = theme

const ErrorMessage = styled.div`
  color: ${COLORS.magenta};
`

const OfficerReviewItem = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin-top: ${spacing.compact};
  margin-bottom: ${spacing.normal};
  justify-content: space-between;
  > span:first-child {
    color: graphite;
    & + span {
      color: ${COLORS.karl};
    }
  }
  > span {
    font-size: ${typography.sizes.large};
  }
`

const FullRow = styled(Row)`
  width: 100%;
`

const FullDiv = styled.div`
  width: 100%;
  > * {
    margin-top: 5px;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: ${spacing.large};
  margin-right: 5px;
  gap: ${spacing.large};

  > div {
    min-width: 45%;

    @media (max-width: ${MAX_WIDTH_MOBILE}) {
      justify-content: center;
      min-width: 50%;
    }
  }
`

const AttributeLabel = styled.div`
  color: ${colors.karl};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.medium};
`

const AttributeContent = styled.div`
  color: ${colors.graphite};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.large};
`

const BlockedByBanner = styled.div`
  padding: ${spacing.large};
  border-radius: ${spacing.small};
  border: 1px solid ${colors.frost};
  background: ${colors.frostLight};
  margin-top: ${spacing.large};
  margin-bottom: ${spacing.large};
`

export const Attribute = ({
  label,
  children
}: {
  label: string
  children: any
}) => (
  <div>
    <AttributeLabel>{label}</AttributeLabel>
    <AttributeContent>{children}</AttributeContent>
  </div>
)

const SectionLabel = styled.div`
  display: flex;
  color: ${colors.graphite};
  font-weight: ${typography.weights.normal};
  font-face: ${typography.faces.display};
  font-size: ${typography.sizes.display.small};
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.large};

  svg {
    margin-left: ${spacing.compact};
    vertical-align: middle;
    cursor: pointer;
  }
`

const Section = ({
  title,
  pageNumber,
  children
}: {
  title: string
  pageNumber?: number
  children: any
}) => {
  const onEditClick = () => {
    const sandbox = api.sandboxMode() ? '&sandbox=true' : ''

    window.location.href = `?page=${pageNumber}${sandbox}`
  }

  return (
    <div>
      <SectionLabel>
        {title}
        {pageNumber && (
          <div onClick={onEditClick}>
            <Pencil />
          </div>
        )}
      </SectionLabel>
      {children}
    </div>
  )
}

const formatQuestionAnswer = (type: string, value: any) => {
  switch (type) {
    case 'address':
      return formatAddress(value)
    case 'multiselect':
      return value.join(', ')
    case 'boolean':
      return String(value) === 'true' ? 'Yes' : 'No'
    case 'document':
      return value ? 'Document uploaded' : 'No document uploaded'
    case 'date_part':
      if (value.day) {
        return `${value.month} ${value.day} ${value.year}`
      } else if (value.month) {
        return `${value.month} ${value.year}`
      } else if (value.quarter) {
        return `${value.quarter} ${value.year}`
      }

      return value.year
    default:
      return String(value)
  }
}

const QuestionReview = ({
  questions,
  answers
}: {
  questions: Question[]
  answers: any
}) => {
  return (
    <>
      {Object.keys(answers).map((key, index) => {
        const questionConfig = questions.find(question => question.key === key)

        if (
          questionConfig &&
          answers[key] !== null &&
          answers[key] !== undefined
        ) {
          return (
            <FlexRow key={`question-${index}`}>
              <Attribute label={questionConfig.label}>
                {formatQuestionAnswer(questionConfig.type, answers[key])}
              </Attribute>
            </FlexRow>
          )
        }
      })}
    </>
  )
}

const ReviewAndFile: Page = ({
  onNext,
  onCancel,
  showSOSPages,
  pageMap,
  pricingData,
  error,
  isSubmitting = false,
  showPoweredByMiddesk,
  progress,
  localJurisdictionRegistrations,
  gustoIntake,
  application
}: PageProps) => {
  const {
    taxTypesByIntent,
    localJurisdictionsInState,
    questions,
    state: filingState
  } = useContext(ApplicationContext)
  const { existingSubscription, fetchingExistingSubscription } = useContext(
    AccountContext
  )
  const { stateAgencyData } = useContext(StateDataContext)
  const [percentOff, setPercentOff] = useState(0.0)
  const { values } = useFormikContext<FormValues>()
  const primaryAddress = values.primary_address
    ? formatAddress(values.primary_address, '\n')
    : undefined
  const mailingAddress = values.mailing_address
    ? formatAddress(values.mailing_address, '\n')
    : undefined
  const { user, account, transactionalAccount } = useContext(AuthContext)
  const selectedPackage = get(
    user,
    'account.settings.agent.package_type',
    'basic'
  )
  const billingProfile = get(account, 'settings.agent.billing_profile')
  const skipPaymentSetting = get(account, 'settings.agent.skip_payment', false)

  const secondaryAddressesCount = (
    values.secondary_addresses[filingState] || []
  ).length

  const isSubmitPage =
    !showSOSPages &&
    (!!existingSubscription ||
      selectedPackage == 'unlimited' ||
      skipPaymentSetting)

  const submitText = gustoIntake
    ? 'Continue to Gusto to complete order'
    : 'Submit'

  const stateData =
    stateAgencyData.find(({ abbr }) => abbr === filingState) || {}
  const agencies = get(stateData, 'tax_registration.agencies', [])
  const managedAgencies = agencies.filter((agency: Agency) => {
    return (
      agency.tax_type === BOTH_SUI_AND_SWH_TYPE ||
      (taxTypesByIntent[MANAGED_BY_MIDDESK] || []).includes(
        agency.tax_type as RegistrationType
      )
    )
  })
  const payrollDateQuestionKey = `${(
    filingState || ''
  ).toLowerCase()}_payroll_date`
  const hiredDateQuestionKey = `${(filingState || '').toLowerCase()}_hire_date`

  useEffect(() => {
    if (api.sandboxMode()) return

    api.get('/ajax/accounts/coupon').then(json => {
      setPercentOff(json.percent_off)
    })
  }, [])

  const recurringPrices = () => {
    if (selectedPackage !== PackageTypes.unlimited) {
      return (
        <Section title={`${STATE_MAP[filingState]?.name} registration`}>
          <FullDiv>
            <FullRow between='xs'>
              <Col xs>
                <b>{capitalize(selectedPackage)}</b>
              </Col>
              <Col>
                <b>
                  {billingProfile?.label
                    ? billingProfile.label
                    : PackageVariations.state_filing[
                        selectedPackage as PackageType
                      ].priceText}
                </b>
              </Col>
            </FullRow>
            {percentOff > 0.0 && (
              <>
                <FullRow between='xs'>
                  <Col xs>Referral Discount</Col>
                  <Col>{percentOff}%</Col>
                </FullRow>
                <Line />
                <FullRow between='xs'>
                  <Col xs>
                    <b>Total</b>
                  </Col>
                  <Col>
                    <b>
                      $
                      {PackageVariations.state_filing[
                        selectedPackage as PackageType
                      ].price *
                        (1.0 - percentOff / 100.0)}
                      &nbsp; /{' '}
                      {selectedPackage === 'unlimited' ? 'year' : 'month'}
                    </b>
                  </Col>
                </FullRow>
              </>
            )}
          </FullDiv>
        </Section>
      )
    }
  }

  const transactionalPrices = () => {
    if (pricingData?.tax_registration_current_prices && filingState) {
      const managedPricingData = pricingData?.tax_registration_current_prices[
        filingState
      ].filter(data =>
        (taxTypesByIntent[MANAGED_BY_MIDDESK] || []).includes(
          data['type'] as RegistrationType
        )
      )

      const localPricingData = localTaxLineItems(
        pricingData,
        localJurisdictionRegistrations,
        localJurisdictionsInState
      )

      const fqPricingData =
        filingState &&
        values.foreign_qualifications &&
        values.foreign_qualifications[0] &&
        values.foreign_qualifications[0].opted_in
          ? fqLineItemsFromApplication(
              application,
              !!values.foreign_qualifications[0].transfer
            )
          : []

      const registrationPrices = [
        ...managedPricingData,
        ...localPricingData,
        ...fqPricingData
      ]

      return (
        <TaxRegistrationLineItems taxRegistrationPrices={registrationPrices} />
      )
    }
  }

  const showPrices = () => {
    return transactionalAccount ? transactionalPrices() : recurringPrices()
  }

  return (
    <>
      <Loader {...{ loading: fetchingExistingSubscription }}>
        <div>
          {gustoIntake && (
            <Section
              title='Registration information'
              pageNumber={pageMap['UnsupportedTaxSituations']}
            >
              <FlexRow>
                <Attribute label='Registration reason'>
                  {REGISTRATION_REASONS[values.registration_reason]}
                </Attribute>
                <Attribute label='Non-profit'>
                  {String(values.non_profit) === 'true' ? 'Yes' : 'No'}
                </Attribute>
              </FlexRow>
            </Section>
          )}
          <Section
            title='Contact information'
            pageNumber={pageMap['ApplicantDetails']}
          >
            <FlexRow>
              <Attribute label='Name'>{values.contact_name}</Attribute>
              <Attribute label='Phone number'>
                {values.contact_phone_number}
              </Attribute>
              <Attribute label='Email'>{values.contact_email}</Attribute>
              <Attribute label='Title'>{values.contact_title}</Attribute>
            </FlexRow>
          </Section>
          <Section
            title='Business information'
            pageNumber={pageMap['CompanyDetails']}
          >
            <FlexRow>
              <Attribute label='Legal business name'>
                {values.legal_name}
              </Attribute>
              <Attribute label='Doing business as (DBA) name'>
                {values.dba_name}
              </Attribute>
              <Attribute label='Entity type'>
                {ENTITY_TYPE_REVIEW_LABELS[values.entity_type]}
              </Attribute>
              {values?.llc_structure && (
                <Attribute label='LLC structure'>
                  {LLC_STRUCTURES[values.llc_structure]}
                </Attribute>
              )}
              {values?.taxed_as_entity_type && (
                <Attribute label='Taxed as entity type'>
                  {TAXED_AS_ENTITY_TYPE_LABELS[values.taxed_as_entity_type]}
                </Attribute>
              )}
              <Attribute label='State of formation'>
                {values.formation_state}
              </Attribute>
              <Attribute label='Date of formation'>
                {values.formation_date}
              </Attribute>
              <Attribute label='Federal employer identification number (FEIN)'>
                {values.ein}
              </Attribute>
              <Attribute label='Description of operations'>
                {values.description_of_operations}
              </Attribute>
              <Attribute label='NAICS code'>{values.naics_code}</Attribute>
              <Attribute label='Industry'>{values.industry}</Attribute>
              <Attribute label='Fiscal year end date'>
                {values.fiscal_year_end}
              </Attribute>
              {values.futa_year != '0' && (
                <Attribute label='FUTA year'>{values.futa_year}</Attribute>
              )}
              <Attribute label='Accounting basis'>
                {ACCOUNTING_BASIS_OPTIONS[values.accounting_basis]}
              </Attribute>
              <Attribute label='Payroll frequency'>
                {PAYROLL_FREQUENCY_OPTIONS[values.payroll_frequency]}
              </Attribute>
            </FlexRow>
            <FlexRow>
              <Attribute label='Primary address'>{primaryAddress}</Attribute>
              {gustoIntake && (
                <Attribute label='Mailing address'>{mailingAddress}</Attribute>
              )}
              {secondaryAddressesCount > 0 && (
                <Attribute
                  label={`Number of business locations in ${STATE_MAP[filingState]?.name}`}
                >
                  {secondaryAddressesCount}
                </Attribute>
              )}
            </FlexRow>
          </Section>
          <Section
            title='Officer / Owner information'
            pageNumber={pageMap['Leadership']}
          >
            <FullDiv>
              {values.owners.map(o =>
                o.object === AGENT_INDIVIDUAL ? (
                  <OfficerReviewItem key={o.name + o.titles.join(', ')}>
                    <span>
                      <b>{o.name}</b> • <span>{o.email}</span> •{' '}
                      <span>{o.titles.join(', ')}</span>
                    </span>
                    <span>{o.ownership_percentage || 0}% ownership</span>
                  </OfficerReviewItem>
                ) : (
                  <OfficerReviewItem key={o.legal_name}>
                    <span>
                      <b>{o.legal_name}</b> • <span>{o.contact_email}</span> •{' '}
                      <span>{o.ein}</span>
                    </span>
                    <span>{o.ownership_percentage || 0}% ownership</span>
                  </OfficerReviewItem>
                )
              )}
            </FullDiv>
          </Section>

          {gustoIntake && (
            <Section
              title={`${STATE_MAP[filingState]?.name} information`}
              pageNumber={pageMap['DynamicStatePage']}
            >
              <QuestionReview
                questions={questions}
                answers={values.questions[filingState] || {}}
              />
            </Section>
          )}

          {gustoIntake && (values.payroll_reports || []).length > 0 && (
            <Section
              title={`${STATE_MAP[filingState]?.name} payroll information`}
              pageNumber={pageMap['PayrollReport']}
            >
              {values.payroll_reports.map(
                ({ quarter, year, amount, estimated_amount }, idx) => {
                  return (
                    <FlexRow key={`payroll-report-${idx}`}>
                      <Attribute
                        label={`Q${quarter} ${year}${
                          !amount ? ' (estimate)' : ''
                        }`}
                      >
                        ${(amount || estimated_amount || 0) / 100.0}
                      </Attribute>
                    </FlexRow>
                  )
                }
              )}
            </Section>
          )}

          {managedAgencies.map((agency: Agency, index: number) => {
            const blockedByPayrollDate = blockedByPayrollDateMessage(
              agency,
              values.questions[filingState][payrollDateQuestionKey]
            )
            const blockedByHiredDate = blockedByHiredDateMessage(
              agency,
              values.questions[filingState][hiredDateQuestionKey]
            )

            if (blockedByPayrollDate) {
              return (
                <BlockedByBanner key={`agency-payroll-${index}`}>
                  {blockedByPayrollDate}
                </BlockedByBanner>
              )
            } else if (blockedByHiredDate) {
              return (
                <BlockedByBanner key={`agency-hired-${index}`}>
                  {blockedByHiredDate}
                </BlockedByBanner>
              )
            }
          })}

          {!skipPaymentSetting && (
            <Section title='Price'>{showPrices()}</Section>
          )}
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <NewRegistrationIntakeFooter
          {...{
            values,
            onNext,
            onCancel,
            isSubmitting,
            error,
            isDisabled: false,
            onClick: () => onNext(values, isSubmitPage),
            progress,
            title: 'Review & submit',
            submitText: isSubmitPage ? submitText : 'Continue',
            showPoweredByMiddesk
          }}
        />
      </Loader>
    </>
  )
}

ReviewAndFile.pageName = 'ReviewAndFile'
ReviewAndFile.title = () => 'Review information'
ReviewAndFile.description = application => {
  if (application.application_invitation?.partner?.slug == GUSTO_ACCOUNT_SLUG) {
    return 'Note that once you proceed to the next step, you will no longer be \
      able to edit the information you have entered.'
  }

  return 'Please confirm the information below is accurate.'
}

export default ReviewAndFile
