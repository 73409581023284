import React, { useState, useEffect, useContext } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import { SelectField, CheckboxField, Icon, theme } from '@middesk/components'

import api from '../../lib/api'
import { stateJurisdictions } from '../../lib/helpers'
import { AuthContext } from '../../contexts/AuthProvider'
import { StyledSelectWrapper } from '../CompanyDetails'
import UnsupportedModal from './UnsupportedModal'
import { ApplicationContext } from '../../contexts/ApplicationProvider'
import { Page, PageProps } from '../../components/Page'
import NewRegistrationIntakeFooter from '../../components/NewRegistrationIntakeFooter'
import { mapStateAbbreviationToName } from '../../components/StateFilter'
import {
  REGISTRATION_REASONS,
  UNSUPPORTED_REGISTRATION_REASONS
} from '../../lib/constants'
import { FormValues } from '../../types'
import { MAX_WIDTH_MOBILE } from '../../components/Body'

const { spacing, colors, typography } = theme

const WidthWrapper = styled.div`
  width: 100%;
  max-width: 520px;

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    max-width: ${MAX_WIDTH_MOBILE};
  }
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing.large};
`

const CancelledIconWrapper = styled.div`
  color: ${colors.red};

  > svg {
    height: 40px;
    width: 40px;
  }
`

const StyledIcon = styled.img`
  height: 100px;
  margin: 0;
  width: 100px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SelectWrapper = styled(StyledSelectWrapper)`
  margin-top: ${spacing.large};
  width: 100%;
`

const StyledCheckboxField = styled(CheckboxField)`
  margin-top: 0;
  margin-bottom: 0;
`

const SectionHeader = styled.div`
  margin-top: ${spacing.large};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
  color: ${colors.graphite};
`

const CheckboxWrapper = styled.div`
  margin-top: ${spacing.small};
`

const ErrorHeader = styled.div`
  position: relative;
  top: -30px;
  color: ${colors.graphite};
  font-size: 24px;
`

const ErrorSubheader = styled.div`
  font-size: 20px;
  margin-top: ${spacing.compact};
  margin-bottom: ${spacing.compact};
`

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  color: ${colors.karl};
  margin-bottom: ${spacing.compact};
`

const validationSchema = (foreignQualificationOnlyRegistration: boolean) => {
  let registrationReasonSchema = yup.string().optional().nullable()
  if (!foreignQualificationOnlyRegistration) {
    registrationReasonSchema = registrationReasonSchema.required(
      'Please indicate why you are registering in state'
    )
  }

  return yup.object().shape({
    registration_reason: registrationReasonSchema,
    is_501c3_organization: yup
      .mixed()
      .when('non_profit', {
        is: non_profit => String(non_profit) === 'true',
        then: yup
          .boolean()
          .required('501(c)(3) organization indication required')
      })
      .when('non_profit', {
        is: non_profit => String(non_profit) !== 'true',
        then: yup.boolean().optional().nullable()
      })
      .nullable()
  })
}

const UnsupportedTaxSituations: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  application,
  error,
  showPoweredByMiddesk,
  progress,
  gustoIntake,
  foreignQualificationOnlyRegistration
}: PageProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { state, setHideTopBar } = useContext(ApplicationContext)
  const { inGuestMode } = useContext(AuthContext)
  const [registrationErrors, setRegistrationErrors] = useState<string[]>([])
  const [showModal, setShowModal] = useState(false)
  const [backLoading, setBackLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [showCanceledRegistration, setShowCanceledRegistration] = useState(
    false
  )
  const hasPayroll =
    stateJurisdictions(values.jurisdictions).length > 0 &&
    !foreignQualificationOnlyRegistration
  const partnerName =
    application?.application_invitation?.partner?.name || 'your provider'

  useEffect(() => {
    updateValidationSchema &&
      updateValidationSchema(
        validationSchema(foreignQualificationOnlyRegistration)
      )
  }, [])

  useEffect(() => {
    if (values && values.non_profit == null) {
      setFieldValue('non_profit', false)
    }
  }, [values])

  useEffect(() => {
    if (!values.non_profit || String(values.non_profit) === 'false') {
      setFieldValue('is_501c3_organization', null)
    }
  }, [values.non_profit])

  useEffect(() => {
    if (stateJurisdictions(values.jurisdictions).length === 0) {
      setFieldValue('already_registered_payroll', null)
    }
  }, [values.jurisdictions])

  const goBack = () => {
    setBackLoading(true)

    if (gustoIntake && application?.redirect_back_uri) {
      window.location.href = application?.redirect_back_uri
    } else {
      onCancel()
    }
  }

  const checkSupportedRegistration = (
    e:
      | React.MouseEventHandler<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>
  ) => {
    const formEvent = e as React.FormEvent<HTMLFormElement>
    formEvent.preventDefault()

    const unsupportedReasons: string[] = []

    if (
      !foreignQualificationOnlyRegistration &&
      UNSUPPORTED_REGISTRATION_REASONS.includes(values.registration_reason)
    ) {
      unsupportedReasons.push(REGISTRATION_REASONS[values.registration_reason])
    }

    if (values.sole_prop) {
      unsupportedReasons.push("I'm a sole proprietor")
    }

    if (values.household_employer) {
      unsupportedReasons.push("I'm a household employer")
    }

    if (hasPayroll && values.already_registered_payroll) {
      unsupportedReasons.push(
        `I've previously registered ${
          values.legal_name || 'this company'
        } for payroll taxes in ${mapStateAbbreviationToName(state)}`
      )
    }

    if (
      String(values.non_profit) === 'true' &&
      String(values.is_501c3_organization) === 'false'
    ) {
      unsupportedReasons.push(
        'This is a non-profit which is not a 501(c)(3) organization'
      )
    }

    if (unsupportedReasons.length == 0) {
      onNext(values)
    } else {
      setRegistrationErrors(unsupportedReasons)
      setShowModal(true)
    }
  }

  const acceptUnsupported = () => {
    setCancelLoading(true)

    api
      .post(`/v1/agent/applications/${application?.id}/cancel`, {
        cancelled_reasons: registrationErrors
      })
      .then(() => {
        if (gustoIntake && application?.redirect_back_uri) {
          window.location.href = application.redirect_back_uri
        } else if (inGuestMode) {
          setShowModal(false)
          setHideTopBar(true)
          setShowCanceledRegistration(true)
        } else {
          setShowModal(false)
          window.location.href = '/'
        }
      })
      .catch(() => {
        setShowModal(false)
        window.location.href = '/'
      })
  }

  if (showCanceledRegistration) {
    return (
      <WidthWrapper>
        <PageWrapper>
          <ErrorHeader>
            Sorry we couldn&apos;t submit your registration
          </ErrorHeader>
          <CancelledIconWrapper>
            <Icon name='crossCircled' />
          </CancelledIconWrapper>
          <ErrorSubheader>Registration request canceled</ErrorSubheader>
          <ErrorContent>
            We&apos;re sorry that we&apos;re unable to submit your registration
            at this time. We&apos;ve let {partnerName} know that we&apos;ve
            canceled this registration request.
          </ErrorContent>
          <ErrorContent>
            If you have any further questions, feel free to send us a note at{' '}
            <a href='mailto: agent@middesk.com'>agent@middesk.com</a>
          </ErrorContent>
        </PageWrapper>
      </WidthWrapper>
    )
  }

  return (
    <WidthWrapper>
      <PageWrapper>
        <StyledIcon src='/icons/writing.svg' />
        <FormWrapper>
          {!foreignQualificationOnlyRegistration && (
            <SelectWrapper>
              <SelectField
                name='registration_reason'
                placeholder='Select reason'
                label='What is your reason for registering?'
              >
                {Object.keys(REGISTRATION_REASONS).map(k => (
                  <option
                    key={k}
                    value={k}
                    label={REGISTRATION_REASONS[k]}
                    selected={values.registration_reason === k}
                  />
                ))}
              </SelectField>
            </SelectWrapper>
          )}

          <SectionHeader>
            Do any of the following apply to your business?
          </SectionHeader>
          <CheckboxWrapper>
            <StyledCheckboxField
              label="I'm a sole proprietor"
              name='sole_prop'
            />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <StyledCheckboxField
              label="I'm a household employer"
              name='household_employer'
            />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <StyledCheckboxField label="I'm a non-profit" name='non_profit' />
          </CheckboxWrapper>
          {hasPayroll && (
            <CheckboxWrapper>
              <StyledCheckboxField
                label={`Previously registered ${
                  values.legal_name || 'this company'
                } for payroll taxes
                in ${mapStateAbbreviationToName(state)}`}
                name='already_registered_payroll'
              />
            </CheckboxWrapper>
          )}

          {values.non_profit && (
            <SelectWrapper>
              <SelectField
                name='is_501c3_organization'
                placeholder='Select Yes / No'
                label='Is your non-profit a 501(c)(3) organization?'
              >
                <option
                  key='true'
                  value='true'
                  label='Yes'
                  selected={String(values.is_501c3_organization) === 'true'}
                />
                <option
                  key='false'
                  value='false'
                  label='No'
                  selected={String(values.is_501c3_organization) === 'false'}
                />
              </SelectField>
            </SelectWrapper>
          )}
        </FormWrapper>
      </PageWrapper>
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel: goBack,
          backLoading,
          isSubmitting,
          error,
          isDisabled: !validationSchema(
            foreignQualificationOnlyRegistration
          ).isValidSync(values),
          onClick: checkSupportedRegistration,
          progress,
          title: 'Business information',
          showPoweredByMiddesk
        }}
      />
      <UnsupportedModal
        isOpen={showModal}
        confirmLabel={inGuestMode ? 'Acknowledge' : 'Back to Home'}
        gustoIntake={gustoIntake}
        acceptUnsupported={acceptUnsupported}
        cancelLoading={cancelLoading}
        hideModal={() => setShowModal(false)}
        registrationErrors={registrationErrors}
      />
    </WidthWrapper>
  )
}

UnsupportedTaxSituations.pageName = 'UnsupportedTaxSituations'
UnsupportedTaxSituations.title = () => 'Tell us about your business'
UnsupportedTaxSituations.description = () =>
  'Provide some details for your registration.'

export default UnsupportedTaxSituations
